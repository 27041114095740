import { Ref, ref } from "vue";
import { sanitize, error, success, info, randomIntFromInterval } from '@/components/Console';
import { Configuration, DefaultApi } from "@/client";
import { Person } from "@/client/models";
import axios from "axios";

export class CommandProcessor {
    private api: DefaultApi;
    context: Person | undefined = undefined;
    accessKey: string | undefined = undefined;

    private currentScenario: Array<Command>;

    constructor(configuration: Configuration, switchScene: () => void) {
        this.api = new DefaultApi(configuration, configuration.basePath, axios);

        const unauthenticatedScenario = [new UnauthenticatedClearCommand, new AuthenticatorCommand(this.api, (context: Person, accessKey : string) => {
            this.context = context;
            this.accessKey = accessKey;
            switchScene();
            this.currentScenario = [new AuthenticatedClearCommand(),
            new WhoAmICommand(this.context),
            new ShowPropertiesCommand(),
            new HelpCommand(),
            new LogoutCommand(() => { this.context = undefined; this.currentScenario = unauthenticatedScenario; }),
            new Command()]
        })];
        this.currentScenario = unauthenticatedScenario;
    }

    async processCommand(command: string, output: Ref<string>) {
        this.currentScenario.find(c => c.matches(command))
            ?.processCommand(command, output);

    }
}

class Command {
    matches(command: string): boolean {
        return true;
    }

    async processCommand(command: string, output: Ref<string>) {
        output.value += error("Unknown command");
    }
}

class UnauthenticatedClearCommand extends Command {
    matches(command: string): boolean {
        return (command == "clear")
    }
    async processCommand(command: string, output: Ref<string>) {
        output.value = "Enter access code:<br/>";
    }
}

class AuthenticatedClearCommand extends Command {
    matches(command: string): boolean {
        return (command == "clear")
    }
    async processCommand(command: string, output: Ref<string>) {
        output.value = "";
    }
}

class AuthenticatorCommand extends Command {
    private api: DefaultApi;
    private successCallBack;

    constructor(api: DefaultApi, successCallback: (context: Person, accessKey : string) => void) {
        super();
        this.api = api;
        this.successCallBack = successCallback;
    }

    matches(command: string): boolean {
        return true;
    }
    async processCommand(command: string, output: Ref<string>) {
        output.value += info("Loading...");
        const interval = new Promise((f) => setTimeout(f, randomIntFromInterval(1000, 1300)));

        interval.then(() => {
            const result = this.api.getUser(sanitize(command.trim()));

            result
                .then((x) => x.data)
                .then(
                (person: Person) => {
                    output.value += success(`Welcome ${person.firstName} ${person.lastName}`);
                    console.log(person);
                    this.successCallBack(person, sanitize(command.trim()));
                    return;
                },
                () => {
                    output.value += error("Unknown code");
                    return;
                }
            );
        }
        );

    }
}

class WhoAmICommand extends Command {
    private context: Person;

    constructor(context: Person) {
        super();
        this.context = context;
    }

    matches(command: string): boolean {
        return (command == "whoami");
    }

    async processCommand(command: string, output: Ref<string>): Promise<void> {
        output.value += success(`${this.context.firstName} ${this.context.lastName} (${this.context.id})`)
    }
}

class ShowPropertiesCommand extends Command {
    matches(command: string): boolean {
        return command.startsWith("show");
    }

    async processCommand(command: string, output: Ref<string>): Promise<void> {
        const args = command.substring(4);

    }
}

class LogoutCommand extends Command {
    private successCallback;

    constructor(successCallback: () => void) {
        super();
        this.successCallback = successCallback;
    }

    matches(command: string): boolean {
        return (command == "logout");
    }

    async processCommand(command: string, output: Ref<string>): Promise<void> {
        output.value += success("Logout successful.")
        output.value += "Enter access code:<br/>";
        this.successCallback();
    }
}

class HelpCommand extends Command {
    matches(command: string): boolean {
        return (command == "help")
    }

    async processCommand(command: string, output: Ref<string>): Promise<void> {
        output.value += success(`Authenticated user registered...`)
        output.value += success(`Field Agent System v5.3`)
        output.value += success(`---`)
        output.value += success(`help            - Show this screen`)
        output.value += success(`whoami          - Outputs information on the authenticated user`)
        output.value += success(`show <property> - Show current state of named property. Leave empty for overview.`)
    }
}