
// https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png
// https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
import { defineComponent, inject, onBeforeMount, ref, watch } from "vue";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LGeoJson } from "@vue-leaflet/vue-leaflet";
import axios from "axios";

import { Person } from "@/client/models";
import { DefaultApi } from "@/client";

export default defineComponent({
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  setup() {
    const providedPersonHandler = inject("person");
    const providedPerson = providedPersonHandler
      ? (providedPersonHandler as () => Person)()
      : ({} as Person);

    const api = inject("api") as DefaultApi;
    const accessKeyHandler = inject("accessKey");
    const accessKey = accessKeyHandler ? (accessKeyHandler as () => string)() : ""
    console.log(`accessKey: ${accessKey}`)
    const contactsComplete = inject("contactsComplete") ? inject("contactsComplete") as () => void : () => {}
    const person = ref(providedPerson);

    const geojson = ref(null);
    const storedAddress = ref(JSON.stringify(person.value?.contact.address));
    const isLoading = ref(false);
    const center = ref(null);

    var requestQueued = false;

    const geojsonOptions = {
      // Options that don't rely on Leaflet methods.
    };

    async function updateMap() {
      const osmUrl = `https://nominatim.openstreetmap.org/search?format=geojson&street=${person.value.contact.address.street}&postalcode=${person.value.contact.address.zip}&city=${person.value.contact.address.city}`;
      //const osmUrl = 'http://localhost'
      const response = await axios
        .get(osmUrl)
        .then((response) => response.data)
        .catch((error) => console.log(JSON.stringify(error)));

      if (response && response.features && response.features.length > 0) {
        storedAddress.value = JSON.stringify(person.value.contact.address);

        geojson.value = response;
        center.value = (
          geojson.value as any
        ).features[0].geometry.coordinates.reverse();
      }
    }

    const entryComplete = (value: Person) => {
      const address = value.contact.address;
      return (
        address &&
        address.street &&
        address.street != "" &&
        address.zip &&
        address.zip != "" &&
        address.city &&
        address.city != "" &&
        value.contact &&
        value.contact.email &&
        value.contact.email != "" &&
        value.contact.mobilePhone &&
        value.contact.mobilePhone != ""
      );
    };

    const dialogVisible = ref(true);
    const confirmationVisible = ref(false);

    const checkConfirmation = () => {
      if (entryComplete(person.value)) {
        console.log("entry is complete");
        dialogVisible.value = false;
        confirmationVisible.value = true;
      }
    };

    const notConfirmed = () => {
      dialogVisible.value = true;
      confirmationVisible.value = false;
    };

    const confirmData = async () => {
      await api.updateUser(accessKey, person.value);
      dialogVisible.value = false;
      confirmationVisible.value = false;
      contactsComplete();
    };

    onBeforeMount(async () => {
      isLoading.value = true;
      await updateMap();
      isLoading.value = false;
    });

    watch(
      () => person,
      async (newValue) => {
        const oldAddress = JSON.parse(storedAddress.value);
        const newAddress = newValue.value.contact.address;

        if (
          newAddress.street != oldAddress.street ||
          newAddress.zip != oldAddress.zip ||
          newAddress.city != oldAddress.city
        ) {
          if (!requestQueued) {
            requestQueued = true;
            const req = new Promise(() =>
              setTimeout(() => {
                updateMap();
                requestQueued = false;
              }, 4000)
            );
            req.then(() => console.log("request complete"));
          }
        }
        storedAddress.value = JSON.stringify(newValue.value?.contact.address);
      },
      { deep: true }
    );

    return {
      person,
      geojson,
      geojsonOptions,
      isLoading,
      center,
      dialogVisible,
      checkConfirmation,
      confirmationVisible,
      notConfirmed,
      confirmData,
      entryComplete,
    };
  },
});
