export function sanitize(input: string): string {
  return new Option(input).innerHTML;
}
export function error(message: string): string {
  return '<span class="text-red-400 ">' + sanitize(message) + "</span><br/>";
}

export function success(message: string): string {
  return '<span class="text-green-200">' + sanitize(message) + "</span><br/>";
}

export function info(message: string): string {
  return '<span class="text-gray-200">' + sanitize(message) + "</span><br/>";
}

export function randomIntFromInterval(min: number, max: number): number {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}