/* tslint:disable */
/* eslint-disable */
/**
 * 2022-event
 * Backend API for the 2022 event
 *
 * OpenAPI spec version: 1.0.0
 * Contact: ben.hermann@googlemail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Event } from '../models';
import { Person } from '../models';
import { EventRsvp } from '../models/eventrsvp';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRsvp: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteRsvp.');
            }
            const localVarPath = `/rsvp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRsvp: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getRsvp.');
            }
            const localVarPath = `/rsvp/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * By passing in the appropriate options, you can search for available inventory in the system 
         * @summary retrieves user information
         * @param {string} accessKey pass the required access key for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (accessKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            if (accessKey === null || accessKey === undefined) {
                throw new RequiredError('accessKey','Required parameter accessKey was null or undefined when calling getUser.');
            }
            const localVarPath = `/user/{accessKey}`
                .replace(`{${"accessKey"}}`, encodeURIComponent(String(accessKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        getVisibility: async (accessKey: string, options: any = {}): Promise<RequestArgs> => {
          // verify required parameter 'accessKey' is not null or undefined
            if (accessKey === null || accessKey === undefined) {
                throw new RequiredError('accessKey','Required parameter accessKey was null or undefined when calling getUser.');
            }
            const localVarPath = `/visibility/{accessKey}`
                .replace(`{${"accessKey"}}`, encodeURIComponent(String(accessKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        getInvite: async (accessKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
              if (accessKey === null || accessKey === undefined) {
                  throw new RequiredError('accessKey','Required parameter accessKey was null or undefined when calling getUser.');
              }
              const localVarPath = `/invite/{accessKey}`
                  .replace(`{${"accessKey"}}`, encodeURIComponent(String(accessKey)));
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, 'https://example.com');
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }
              const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;
  
              const query = new URLSearchParams(localVarUrlObj.search);
              for (const key in localVarQueryParameter) {
                  query.set(key, localVarQueryParameter[key]);
              }
              for (const key in options.query) {
                  query.set(key, options.query[key]);
              }
              localVarUrlObj.search = (new URLSearchParams(query)).toString();
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
  
              return {
                  url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                  options: localVarRequestOptions,
              };
          },
          getRsvps: async (accessKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
              if (accessKey === null || accessKey === undefined) {
                  throw new RequiredError('accessKey','Required parameter accessKey was null or undefined when calling getUser.');
              }
              const localVarPath = `/rsvp/{accessKey}`
                  .replace(`{${"accessKey"}}`, encodeURIComponent(String(accessKey)));
              // use dummy base URL string because the URL constructor only accepts absolute URLs.
              const localVarUrlObj = new URL(localVarPath, 'https://example.com');
              let baseOptions;
              if (configuration) {
                  baseOptions = configuration.baseOptions;
              }
              const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
              const localVarHeaderParameter = {} as any;
              const localVarQueryParameter = {} as any;
  
              const query = new URLSearchParams(localVarUrlObj.search);
              for (const key in localVarQueryParameter) {
                  query.set(key, localVarQueryParameter[key]);
              }
              for (const key in options.query) {
                  query.set(key, options.query[key]);
              }
              localVarUrlObj.search = (new URLSearchParams(query)).toString();
              let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
              localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
  
              return {
                  url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                  options: localVarRequestOptions,
              };
          },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rsvp: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/rsvp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary stores user information
         * @param {string} accessKey pass the required access key for a user
         * @param {Person} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (accessKey: string, body?: Person, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            if (accessKey === null || accessKey === undefined) {
                throw new RequiredError('accessKey','Required parameter accessKey was null or undefined when calling updateUser.');
            }
            const localVarPath = `/user/{accessKey}`
                .replace(`{${"accessKey"}}`, encodeURIComponent(String(accessKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        reportRsvp: async (accessKey: string, body?: Array<EventRsvp>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            if (accessKey === null || accessKey === undefined) {
                throw new RequiredError('accessKey','Required parameter accessKey was null or undefined when calling updateUser.');
            }
            const localVarPath = `/rsvp/{accessKey}`
                .replace(`{${"accessKey"}}`, encodeURIComponent(String(accessKey)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRsvp(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteRsvp(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvents(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Event>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getEvents(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRsvp(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getRsvp(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRsvps(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventRsvp>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getRsvps(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * By passing in the appropriate options, you can search for available inventory in the system 
         * @summary retrieves user information
         * @param {string} accessKey pass the required access key for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(accessKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUser(accessKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        async getVisibility(accessKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<String>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getVisibility(accessKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        async getInvite(accessKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<String>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getInvite(accessKey, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rsvp(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).rsvp(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary stores user information
         * @param {string} accessKey pass the required access key for a user
         * @param {Person} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(accessKey: string, body?: Person, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Person>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updateUser(accessKey, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },

        async reportRsvp(accessKey: string, body?: Array<EventRsvp>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventRsvp>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).reportRsvp(accessKey, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRsvp(id: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).deleteRsvp(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvents(options?: any): AxiosPromise<Event> {
            return DefaultApiFp(configuration).getEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRsvp(id: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).getRsvp(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRsvps(options?: any): AxiosPromise<Array<EventRsvp>> {
            return DefaultApiFp(configuration).getRsvps(options).then((request) => request(axios, basePath));
        },
        /**
         * By passing in the appropriate options, you can search for available inventory in the system 
         * @summary retrieves user information
         * @param {string} accessKey pass the required access key for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(accessKey: string, options?: any): AxiosPromise<Person> {
            return DefaultApiFp(configuration).getUser(accessKey, options).then((request) => request(axios, basePath));
        },
        getVisibility(accessKey: string, options?: any): AxiosPromise<Array<String>> {
            return DefaultApiFp(configuration).getVisibility(accessKey, options).then((request) => request(axios, basePath));
        },
        getInvite(accessKey: string, options?: any): AxiosPromise<Array<String>> {
            return DefaultApiFp(configuration).getInvite(accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rsvp(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).rsvp(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary stores user information
         * @param {string} accessKey pass the required access key for a user
         * @param {Person} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(accessKey: string, body?: Person, options?: any): AxiosPromise<Person> {
            return DefaultApiFp(configuration).updateUser(accessKey, body, options).then((request) => request(axios, basePath));
        },

        reportRsvp(accessKey: string, body?: Array<EventRsvp>, options?: any): AxiosPromise<Array<EventRsvp>> {
            return DefaultApiFp(configuration).reportRsvp(accessKey, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteRsvp(id: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteRsvp(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getEvents(options?: any) {
        return DefaultApiFp(this.configuration).getEvents(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRsvp(id: string, options?: any) {
        return DefaultApiFp(this.configuration).getRsvp(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getRsvps(options?: any) {
        return DefaultApiFp(this.configuration).getRsvps(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * By passing in the appropriate options, you can search for available inventory in the system 
     * @summary retrieves user information
     * @param {string} accessKey pass the required access key for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUser(accessKey: string, options?: any) {
        return DefaultApiFp(this.configuration).getUser(accessKey, options).then((request) => request(this.axios, this.basePath));
    }
    public getVisibility(accessKey: string, options?: any) {
        return DefaultApiFp(this.configuration).getVisibility(accessKey, options).then((request) => request(this.axios, this.basePath));
    }
    public getInvite(accessKey: string, options?: any) {
        return DefaultApiFp(this.configuration).getInvite(accessKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rsvp(options?: any) {
        return DefaultApiFp(this.configuration).rsvp(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary stores user information
     * @param {string} accessKey pass the required access key for a user
     * @param {Person} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateUser(accessKey: string, body?: Person, options?: any) {
        return DefaultApiFp(this.configuration).updateUser(accessKey, body, options).then((request) => request(this.axios, this.basePath));
    }
    public reportRsvp(accessKey: string, body?: Array<EventRsvp>, options?: any) {
        return DefaultApiFp(this.configuration).reportRsvp(accessKey, body, options).then((request) => request(this.axios, this.basePath));
    }
}
