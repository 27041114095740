
import Console from "@/components/Console.vue";
import ContactData from '@/components/ContactData.vue';
import MissionBriefing from '@/components/MissionBriefing.vue';
import { onBeforeMount, provide, ref, Ref } from "@vue/runtime-core";
import { Configuration, DefaultApi } from "./client";
import { CommandProcessor } from '@/tools/CommandProcessor';
import { SceneDirector } from '@/tools/SceneDirector';
import axios from 'axios';

export default {
  components: { Console, ContactData, MissionBriefing },
  setup() {
    const sceneDirector = new SceneDirector("console");
    const activeScene = sceneDirector.activeScene;
    const conf = new Configuration({
      basePath: "https://007wedding.com/api", baseOptions: {}
    });

    const switchToIntro = () => { 
      sceneDirector.switchTo("intro") 
    }

    const switchToContactData = () => { 
      sceneDirector.switchTo("contactData") 
    }
    const switchToMissionData = () => {
      sceneDirector.switchTo("bond");
    }

    const logout = () => {
      commandProcessor.processCommand("logout", consoleOutput);
      sceneDirector.switchTo("console");
    }

    const outro = () => {
      commandProcessor.processCommand("logout", consoleOutput);
      sceneDirector.switchTo("outro");
    }
    const commandProcessor = new CommandProcessor(conf, switchToIntro);
    const consoleOutput =  ref("Enter access code: <br/>");

    provide(
      "commandProcessor", async function processCommand(command: string, output: Ref<string>) {
        commandProcessor.processCommand(command, output);
      }
    );
    provide("context", () => commandProcessor.context?.id);
    provide("person", () => commandProcessor.context)
    provide("api", new DefaultApi(conf, conf.basePath, axios))
    provide("contactsComplete", switchToMissionData);
    provide("showContactData", switchToContactData)
    provide("logout", logout)
    provide("outro", outro)
    provide("accessKey", () => commandProcessor.accessKey)
    provide("consoleOutput", consoleOutput);
    onBeforeMount(() => {});

    return { sceneDirector, activeScene };
  },
};
