
import { Ref, ref } from "@vue/reactivity";
import { inject, onMounted } from "@vue/runtime-core";
import { sanitize, error, randomIntFromInterval } from '@/components/Console';

export default {
  setup() {
    const input = ref("");
    const inputClass = ref("visible");
    const commandProcessor = inject("commandProcessor");
    const output =  inject("consoleOutput") ? inject("consoleOutput") as Ref<string> : ref("Nope");
    const context = inject("context");
    const currentContextRef = ref(() => sanitize(currentContext()));

    function mirrorCommand(command: string): string {
      return (
        '<span class="text-gray-400">' + sanitize(currentContext()) + '> ' + sanitize(command) + "</span><br/>"
      );
    }

    function currentContext() : string {
      return context ? (context as () => string)() : ""
    }

    async function processCommand(command: string, output: Ref<string>) {
      /* eslint-disable no-unused-vars */
      if (commandProcessor) {
        (
          commandProcessor as (
            command: string,
            output: Ref<string>
          ) => Promise<void>
        )(command, output);
      } else {
        output.value += error("Unknown command");
      }
      /* eslint-enable no-unused-vars */
    }

    const focusElement = () => {
      const inputElement =  document.getElementById("input");
      if (inputElement != null) inputElement!.focus();
    }

    const onExecute = async () => {
      if (input.value.trim() == "") return;
      inputClass.value = "invisible";
      var command = input.value;
      output.value += mirrorCommand(input.value);
      input.value = "";
      await new Promise((f) => setTimeout(f, randomIntFromInterval(400, 1000)));
      await processCommand(command, output);
      inputClass.value = "visible";

      await new Promise((f) => setTimeout(f, 100));
      focusElement();
    };

    onMounted(() => {
      document.getElementById("input")!.onblur = () => {
        // never lose focus in the console. :)
        focusElement();
      };
      focusElement();
    });

    return { input, output, onExecute, inputClass, currentContextRef};
  },
};
