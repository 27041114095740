import { ref, Ref } from "vue";

export class SceneDirector {
    activeScene : Ref<string> 

    constructor(initialScene : string) {
        this.activeScene = ref(initialScene);
    } 

    switchTo(targetScene : string) {
        this.activeScene.value = targetScene;
        console.debug(`Switched to ${targetScene}.`)
    }

}