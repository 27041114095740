import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-black text-green-400 font-mono w-screen h-screen flex flex-col text-lg overflow-hidden overscroll-none" }
const _hoisted_2 = { class: "flex-grow overscroll-none overflow-hidden" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "flex-grow-0 overflow-hidden pl-4 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", {
        class: "absolute bottom-10 overscroll-none overflow-hidden leading-none pl-4",
        innerHTML: $setup.output
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", {
        class: _normalizeClass($setup.inputClass)
      }, _toDisplayString($setup.currentContextRef()) + "> ", 3),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["bg-black text-green-400 bottom-1 border-0 outline-none w-5/6", $setup.inputClass]),
        type: "text",
        name: "input",
        id: "input",
        autocomplete: "off",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.input) = $event)),
        onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => ($setup.onExecute && $setup.onExecute(...args)), ["enter"]))
      }, null, 34), [
        [_vModelText, $setup.input]
      ])
    ])
  ]))
}