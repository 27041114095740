
import { defineComponent, inject, onBeforeMount, Ref, ref } from "vue";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LGeoJson } from "@vue-leaflet/vue-leaflet";
import { DefaultApi } from "@/client";
import { EventRsvp } from "@/client/models/eventrsvp";
import { Person } from "@/client/models";

interface Message {
  id: number;
  title: string;
  text: string;
  date?: Date;
  address?: string;
  lat?: number;
  long?: number;
  invited: boolean;
  visible: boolean;
  accepted: boolean;
  rejected: boolean;
  eventkey: string;
}

export default defineComponent({
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  setup() {
    const messages = ref([
      {
        id: 1,
        title: "MISSION: ... and make it complete",
        text: `Im folgenden findet ihr alle Informationen für die Hochzeitsfeier von Leon & Ben.
Bevor es aber losgeht ein paar Hinweise:
1. Fühlt euch nicht gezwungen - wir freuen uns sehr wenn ihr dabei seid, aber wir sind euch nicht böse, wenn ihr absagt oder etwas dazwischen kommt.
2. Fühlt euch wohl - zieht nichts an, weil "sich das so gehört", sondern etwas in dem ihr euch wohlfühlt.
3. Macht euch keinen Stress - niemand muss pünktlich sein. 
4. Unsere Trauzeugen sind Lennard Frost (<a class="text-blue-500" href="tel:+4917634629992">0176 34 62 99 92</a>) und Philipp Gresch (<a class="text-blue-500" href="tel:+4917662826127">0176 62 82 61 27</a>). 
5. Auf das Thema Geschenk sind wir schon häufig angesprochen worden. Wir sagen dazu: Unsere Miss Moneypenny nimmt gerne alles an, was ihrem Namen gerecht wird, denn unser Hauptquartier braucht neue Ausstattung.
       `,
        visible: true,
      },
      {
        id: 2,
        title: "Warm-Up and Get Together",
        date: new Date("2022-06-16T15:00:00"),
        text: `Bevor es richtig losgeht, möchten wir euch gerne zu uns nach Hause einladen. Auf unserer Terrasse wird es Kaffee und Kuchen geben. Zur vorgerückter Stunde werfen wir sicherlich auch noch etwas auf den Grill.
Kommt einfach ab 15 Uhr vorbei und bringt gute Laune mit. Wenn es später wird, wird es später. Aber so gegen 20/21 Uhr bitten wir euch dann zu gehen, denn wir wollen alle für den nächsten Tag natürlich ausgeschlafen sein.`,
        address: "Leon & Ben, Stephanstraße 6 (MI6), 64295 Darmstadt",
        lat: 49.8650085,
        long: 8.6394039,
        invited: true,
        visible: true,
        eventkey: "warmup",
      },
      {
        id: 3,
        title: "Standesamt",
        date: new Date("2022-06-17T11:00:00"),
        text: `Damit es eine Hochzeit ist, müssen wir beide einmal Ja gesagt haben. 
Im kleinen, familiären Kreis machen wir das im Standesamt Darmstadt direkt am Marktplatz. Man kann leider nicht direkt vor die Tür fahren, aber das Parkhaus Justus-Liebig-Garage ist gleich in der Nähe. Oder man nimmt sich ein Taxi zum Marktplatz.
`,
        address: "Standesamt Darmstadt, Marktplatz 8, 64283 Darmstadt",
        lat: 49.8721534,
        long: 8.6558154,
        invited: true,
        visible: true,
        eventkey: "regoffice",
      },
      {
        id: 4,
        title: "Sektempfang",
        date: new Date("2022-06-17T12:00:00"),
        text: `Nach dem ersten Ja ist vor dem ersten Sekt. Frisch vermählt möchten wir euch zu Sekt und Häppchen einladen. Mit einen wunderschönen Blick über den Woog - Darmstadts großem See - wollen wir erstmal mit euch anstoßen und den Tag beginnen lassen.`,
        address: "Woogscafé, Beckstraße 44, 64297 Darmstadt",
        lat: 49.8719,
        long: 8.6680723,
        invited: true,
        visible: true,
        eventkey: "reception",
      },
      {
        id: 5,
        title: "Kirchliche Trauung",
        date: new Date("2022-06-17T15:00:00"),
        text: `Wer einmal Ja sagt, sagt auch ganz schnell ein zweites mal Ja. In der Martinskirche, mit der wir viele schöne Erinnerungen verbinden, wollen wir mit euch einen Gottesdienst feiern.
Da es für uns alle noch ein langer Tag wird, brauchen wir danach sicherlich einen starken Espresso. Und den gibt es direkt auf dem Kirchplatz für euch.`,
        address:
          "Ev. Martinskirche Darmstadt, Heinheimer Straße 41a, 64289 Darmstadt",
        lat: 49.88,
        long: 8.6610011,
        invited: true,
        visible: true,
        eventkey: "church",
      },
      {
        id: 6,
        date: new Date("2022-06-17T17:00:00"),
        title: "Bus nach Langen",
        text: `Der Tag wird lang und vielleicht will man nicht unbedingt fahren. Wir lassen einen Bus fahren. 
Falls Du unten keinen Missions-Knopf findest, dachten wir du fährst vielleicht selber. Lass uns wissen, wenn wir falsch lagen.`,
        visible: false,
        invited: true,
        eventkey: "busto",
      },
      {
        id: 7,
        title: "Gartenfest",
        date: new Date("2022-06-17T18:00:00"),
        text: `So ein Tag will gefeiert sein. Wir treffen uns in Langen im Garten des Mehrgenerationen-Hauses Görich-Reinel und lassen uns begrillen. Für Speisen und Getränke ist also reichlich gesorgt, lediglich auf das gute Wetter müssen wir hoffen.
Wir haben aber eine Bitte an euch: Wir mögen keine Spiele und keine der üblichen Hochzeitsaktionen. Daher bitten wir euch auf all das zu verzichten, damit wir alle einen schönen Abend haben.`,
        address: "Garten hinterm Haus, Wallstraße 26, 63225 Langen",
        lat: 49.9905009,
        long: 8.677307995224215,
        invited: true,
        visible: true,
        eventkey: "garden",
      },
      {
        id: 8,
        date: new Date("2022-06-18T00:00:00"),
        title: "Bus nach Darmstadt",
        text: `Auch die schönste Feier geht einmal zu Ende. Wir lassen uns in Langen abholen und bringen euch wieder nach Darmstadt zurück.
Falls Du unten keinen Missions-Knopf findest, dachten wir du fährst vielleicht selber. Lass uns wissen, wenn wir falsch lagen.`,
        invited: false,
        visible: true,
        eventkey: "busback",
      },
      {
        id: 9,
        title: "Stadtrundgang mit Aurora DeMeehl",
        date: new Date("2022-06-18T11:00:00"),
        text: `Das beste Bond-Girl Darmstadts führt euch durch die Stadt und zeigt euch die schönsten Orte. Das sollte man nicht verpassen.`,
        address: "Treffpunkt zentral in Darmstadt (wird noch angekündigt)",
        invited: true,
        visible: true,
        eventkey: "citytour",
      },
      {
        id: 10,
        title: "Gemeinsames Mittagessen",
        date: new Date("2022-06-18T13:00:00"),
        text: `So eine Stadtführung macht hungrig. Wir treffen uns im Restaurant (heute mal jeder mit dem eigenen Portemonnaie) zur gemeinsamen Stärkung.`,
        address: "Treffpunkt in Darmstadt (wird noch angekündigt) ",
        invited: true,
        visible: true,
        eventkey: "lunch",
      },
      {
        id: 11,
        title: "Sexy James Bond Cocktail Party",
        date: new Date("2022-06-18T20:00:00"),
        text: `Bei Cocktails und leichten Häppchen (kein volles Abendessen) lassen wir das Wochenende auf unserer Terrasse ausklingen. Wir feiern bei stilvoller Musik in den Sonnenuntergang hinein. Also zwei Vodka Martini für die frisch Vermählten und Drinks für die ganze Runde. Geschüttelt, nicht gerührt.`,
        address: "Leon & Ben, Stephanstraße 6 (MI6), 64295 Darmstadt",
        lat: 49.8650085,
        long: 8.6394039,
        invited: true,
        visible: true,
        eventkey: "cocktails",
      },
      {
        id: 12,
        title: "Wellness & Spa",
        date: new Date("2022-06-19T14:00:00"),
        text: `Detox und Entspannung sind angesagt nach diesem Wochenende. Wir verbringend den Tag im Spa. Ob du mitkommen willst oder nicht, bestimmst du ganz unabhängig von uns.`,
        address: "wird noch angekündigt",
        invited: true,
        visible: true,
        eventkey: "wellness",
      },
    ]) as Ref<Array<Message>>;

    const isLoading = ref(false);
    const api = inject("api") as DefaultApi;
    const accessKeyHandler = inject("accessKey");
    const accessKey = accessKeyHandler
      ? (accessKeyHandler as () => string)()
      : "";

    const invites = ref([] as Array<String>);
    const visibilities = ref([] as Array<String>);
    const rsvps = ref([] as Array<EventRsvp>);

    onBeforeMount(async () => {
      isLoading.value = true;
      await getInvites();
      await getVisibilites();
      await getRsvps();

      messages.value.forEach((m) => {
        m.visible = m.id == 1 ? true : visibilities.value.findIndex((v) => m.eventkey == v) > -1;
        m.invited = invites.value.findIndex((v) => m.eventkey == v) > -1;
        m.accepted =
          rsvps.value.findIndex((v) => m.eventkey == v.key) > -1
            ? rsvps.value.find((v) => m.eventkey == v.key)!.accepted
            : false;
        m.rejected =
          rsvps.value.findIndex((v) => m.eventkey == v.key) > -1
            ? rsvps.value.find((v) => m.eventkey == v.key)!.rejected
            : false;
      });

      isLoading.value = false;
    });

    const getInvites = async () => {
      const inviteResponse = api.getInvite(accessKey);
      await inviteResponse
        .then((x) => x.data)
        .then(
          (i: Array<String>) => {
            invites.value = i;
          },
          () => {
            console.debug("Could not get invite records.");
            return [];
          }
        );
    };

    const getVisibilites = async () => {
      const visibilityResponse = api.getVisibility(accessKey);
      await visibilityResponse
        .then((x) => x.data)
        .then(
          (v: Array<String>) => {
            visibilities.value = v;
          },
          () => {
            console.debug("Could not get visibility records.");
            return [];
          }
        );
    };

    const getRsvps = async () => {
      const rsvpResponse = api.getRsvps(accessKey);
      await rsvpResponse
        .then((x) => x.data)
        .then(
          (r: Array<EventRsvp>) => {
            rsvps.value = r;
          },
          () => {
            console.debug("Could not get rsvp records.");
            return [];
          }
        );
    };

    const showContactData = inject("showContactData")
      ? (inject("showContactData") as () => void)
      : () => {};

    const logout = inject("logout")
      ? (inject("logout") as () => void)
      : () => {};

    const outro = inject("outro")
      ? (inject("outro") as () => void)
      : () => {};

    const acceptMission = (id: number) => {
      const mission = messages.value.find((m) => m.id == id);
      if (!mission) return;

      const eventRsvp = {
        key: mission.eventkey,
        accepted: true,
        rejected: false,
      };
      api.reportRsvp(accessKey, [eventRsvp]);

      mission!.accepted = true;
      mission!.rejected = false;
    };

    const rejectMission = (id: number) => {
      const mission = messages.value.find((m) => m.id == id);
      if (!mission) return;

      const eventRsvp = {
        key: mission.eventkey,
        accepted: false,
        rejected: true,
      };
      api.reportRsvp(accessKey, [eventRsvp]);

      mission!.accepted = false;
      mission!.rejected = true;
    };

    const toGeoJson = (m: Message) => {
      return {
        type: "FeatureCollection",
        licence:
          "Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright",
        features: [
          {
            type: "Feature",
            properties: {
              place_id: 79143880,
              osm_type: "node",
              osm_id: 7482356130,
              display_name: m.address,
              place_rank: 30,
              category: "place",
              type: "house",
              importance: 0.44100000000000006,
            },
            bbox: [
              m.long! + 0.00005,
              m.lat! - 0.00005,
              m.long! - 0.00005,
              m.lat! + 0.00005,
            ],
            geometry: { type: "Point", coordinates: [m.long, m.lat] },
          },
        ],
      };
    };
    const providedPersonHandler = inject("person");
    const providedPerson = providedPersonHandler
      ? (providedPersonHandler as () => Person)()
      : ({} as Person);

    const print = () => {
      window.print();
    }

    return {
      messages,
      isLoading,
      showContactData,
      toGeoJson,
      acceptMission,
      rejectMission,
      logout,
      outro,
      print,
      providedPerson
    };
  },
});
