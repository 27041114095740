import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"
import _imports_0 from './assets/intro.mp4'
import _imports_1 from './assets/bond.mp4'
import _imports_2 from './assets/outro.mp4'


const _hoisted_1 = {
  key: 1,
  class: "h-screen w-screen bg-black grid place-content-center"
}
const _hoisted_2 = {
  key: 2,
  class: "h-screen w-screen bg-black grid place-content-center"
}
const _hoisted_3 = {
  key: 3,
  class: "h-screen w-screen bg-black grid place-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Console = _resolveComponent("Console")!
  const _component_ContactData = _resolveComponent("ContactData")!
  const _component_MissionBriefing = _resolveComponent("MissionBriefing")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.activeScene == 'console')
      ? (_openBlock(), _createBlock(_component_Console, { key: 0 }))
      : _createCommentVNode("", true),
    ($setup.activeScene == 'intro')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("video", {
            class: "w-screen",
            src: _imports_0,
            autoplay: "",
            onEnded: _cache[0] || (_cache[0] = ($event: any) => ($setup.sceneDirector.switchTo('contactData')))
          }, null, 32)
        ]))
      : _createCommentVNode("", true),
    ($setup.activeScene == 'bond')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("video", {
            class: "w-screen",
            src: _imports_1,
            autoplay: "",
            onEnded: _cache[1] || (_cache[1] = ($event: any) => ($setup.sceneDirector.switchTo('mission')))
          }, null, 32)
        ]))
      : _createCommentVNode("", true),
    ($setup.activeScene == 'outro')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("video", {
            class: "w-screen",
            src: _imports_2,
            autoplay: "",
            onEnded: _cache[2] || (_cache[2] = ($event: any) => ($setup.sceneDirector.switchTo('console')))
          }, null, 32)
        ]))
      : _createCommentVNode("", true),
    ($setup.activeScene == 'contactData')
      ? (_openBlock(), _createBlock(_component_ContactData, { key: 4 }))
      : _createCommentVNode("", true),
    ($setup.activeScene =='mission')
      ? (_openBlock(), _createBlock(_component_MissionBriefing, { key: 5 }))
      : _createCommentVNode("", true)
  ], 64))
}